import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { projectsEndpoints } from './projectsEndpoints';

export const Api = createApi({
  reducerPath: 'Api',
  tagTypes: ['Api', 'Notes', 'StudentNotes', 'Edit', 'Project'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),
  endpoints: (build) => ({
    getStudents: build.query({
      query: (getAccess) => ({
        url: `/api/moodle_users/one_c_user_info`,
        methods: 'GET',
        headers: getAccess,
      }),
    }),
    getMoodleStudents: build.query({
      query: (tokenPlusId) => ({
        url: `/api/personal_accounts/account_info?moodle_user_id=${tokenPlusId.moodleId}`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
    }),

    getPredictionGrades: build.query({
      query: (tokenPlusCourses) => ({
        url: `/ml/prediction/students/${tokenPlusCourses.moodleId}?pred_passing=0&course_ids=${tokenPlusCourses.courses}`,
        methods: 'GET',
        headers: tokenPlusCourses.getAccess,
      }),
    }),
    getCoursesList: build.query({
      query: (getAccess) => ({
        url: `/generator/api/courses-list/`,
        methods: 'GET',
        headers: getAccess,
      }),
    }),
    getStudyPlans: build.query({
      query: (dataGenerator) => ({
        url: `/generator/api/rpd/find_study_plans/?course_id=${dataGenerator.discipline}`,
        methods: 'GET',
        headers: dataGenerator.getAccess,
      }),
    }),
    getMoodleInstructor: build.query({
      query: (tokenPlusId) => ({
        url: `/api/personal_accounts/instructor_account_info?moodle_user_id=${tokenPlusId.moodleId}`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
    }),
    getCourseInstructor: build.query({
      query: (tokenPlusId) => ({
        url: `/api/moodle_users/instructor_courses_with_feedbacks?moodle_user_id=${tokenPlusId.moodleId}`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
    }),
    getCourseTeam: build.query({
      query: (tokenPlusId) => ({
        url: `/api/courses/course_info?moodle_course_id=${tokenPlusId.moodleId}`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
    }),
    getFeedbacksList: build.query({
      query: (tokenPlusId) => ({
        url: `/api/feedbacks/list?course=${tokenPlusId.moodleId}`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
    }),
    getFeedbackQuestion: build.query({
      query: (tokenPlusdata) => ({
        url: `/api/feedbacks/items?feedback=${tokenPlusdata.feedbackId}`,
        methods: 'GET',
        headers: tokenPlusdata.getAccess,
      }),
    }),
    getFeedbackAnswers: build.query({
      query: (tokenPlusId) => ({
        url: `/api/feedbacks/responses?item=${tokenPlusId.feedbackId}`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
    }),
    getFeedbackAllAnswers: build.query({
      query: (tokenPlusId) => ({
        url: `/api/feedbacks/responses?feedback=${tokenPlusId.feedbackId}`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
    }),
    getFeedbacksByFeedbackID: build.query({
      query: (tokenPlusdata) => ({
        url: `/api/feedbacks/list?moodle_feedback_id=${tokenPlusdata.feedbackId}`,
        methods: 'GET',
        headers: tokenPlusdata.getAccess,
      }),
    }),
    getFeedbacksByResponsesRelatedToStuff: build.query({
      query: (tokenPlusdata) => ({
        url: `/api/feedbacks/get-responses-related-to/${tokenPlusdata.feedbackId}?indicator_question_id=${tokenPlusdata.question_id}&staff_roles_ids=${tokenPlusdata.roles_ids}&staff_id=${tokenPlusdata.staff_id}&item_id=${tokenPlusdata.item_id}`,
        methods: 'GET',
        headers: tokenPlusdata.getAccess,
      }),
    }),
    getFeedbacksByResponsesRelatedToStuffCommon: build.query({
      query: (tokenPlusdata) => ({
        url: `/api/feedbacks/get-responses-related-to/${tokenPlusdata.feedbackId}?indicator_question_id=${tokenPlusdata.question_id}&staff_roles_ids=${tokenPlusdata.roles_ids}&staff_id=${tokenPlusdata.staff_id}`,
        methods: 'GET',
        headers: tokenPlusdata.getAccess,
      }),
    }),
    getNotes: build.query({
      query: (tokenPlusdata) => ({
        url: `/api/notes/instructor_note_editable/?edited_by_email=${tokenPlusdata.email}`,
        methods: 'GET',
        headers: tokenPlusdata.getAccess,
      }),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'Notes', id })), 'Notes'] : ['Notes'],
    }),
    deleteNotes: build.mutation({
      query: (tokenPlusId) => ({
        url: `/api/notes/instructor_note_editable/${tokenPlusId.id}/`,
        method: 'DELETE',
        headers: tokenPlusId.getAccess,
      }),
      invalidatesTags: ['Notes'],
    }),
    addNotes: build.mutation({
      query: (tokenPlusBody) => ({
        url: `/api/notes/instructor_note_editable/`,
        method: 'POST',
        headers: tokenPlusBody.getAccess,
        body: tokenPlusBody.body,
      }),
      invalidatesTags: ['Notes'],
    }),
    edditNotes: build.mutation({
      query: (tokenPlusBody) => ({
        url: `/api/notes/instructor_note_editable/${tokenPlusBody.id}/`,
        method: 'PUT',
        headers: tokenPlusBody.getAccess,
        body: tokenPlusBody.body,
      }),
      invalidatesTags: ['Notes'],
    }),
    archivedNotes: build.mutation({
      query: (tokenPlusBody) => ({
        url: `/api/notes/instructor_note_editable/${tokenPlusBody.id}/`,
        method: 'PATCH',
        headers: tokenPlusBody.getAccess,
        body: tokenPlusBody.body,
      }),
      invalidatesTags: ['Notes'],
    }),
    getStudentNotes: build.query({
      query: (tokenPlusdata) => ({
        url: `/api/notes/student_note_editable/?edited_by_email=${tokenPlusdata.email}`,
        methods: 'GET',
        headers: tokenPlusdata.getAccess,
      }),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'StudentNotes', id })), 'StudentNotes'] : ['StudentNotes'],
    }),
    deleteStudentNotes: build.mutation({
      query: (tokenPlusId) => ({
        url: `/api/notes/student_note_editable/${tokenPlusId.id}/`,
        method: 'DELETE',
        headers: tokenPlusId.getAccess,
      }),
      invalidatesTags: ['StudentNotes'],
    }),
    addStudentNotes: build.mutation({
      query: (tokenPlusBody) => ({
        url: `/api/notes/student_note_editable/`,
        method: 'POST',
        headers: tokenPlusBody.getAccess,
        body: tokenPlusBody.body,
      }),
      invalidatesTags: ['StudentNotes'],
    }),
    edditStudentNotes: build.mutation({
      query: (tokenPlusBody) => ({
        url: `/api/notes/student_note_editable/${tokenPlusBody.id}/`,
        method: 'PUT',
        headers: tokenPlusBody.getAccess,
        body: tokenPlusBody.body,
      }),
      invalidatesTags: ['StudentNotes'],
    }),
    archivedStudentNotes: build.mutation({
      query: (tokenPlusBody) => ({
        url: `/api/notes/student_note_editable/${tokenPlusBody.id}/`,
        method: 'PATCH',
        headers: tokenPlusBody.getAccess,
        body: tokenPlusBody.body,
      }),
      invalidatesTags: ['StudentNotes'],
    }),

    getEditableInstructor: build.query({
      query: (tokenPlusId) => ({
        url: `/api/moodle_users/instructor_editable_personal_info/${tokenPlusId.moodleId}/`,
        methods: 'GET',
        headers: tokenPlusId.getAccess,
      }),
      providesTags: ['Edit'],
    }),
    createInstructorInfo: build.mutation({
      query: (tokenPlusBody) => ({
        url: `/api/moodle_users/instructor_editable_personal_info/`,
        method: 'POST',
        headers: tokenPlusBody.getAccess,
        body: tokenPlusBody.body,
      }),
      invalidatesTags: ['Edit'],
    }),
    changeInstructorInfo: build.mutation({
      query: (tokenPlusMoodleId) => ({
        url: `/api/moodle_users/instructor_editable_personal_info/${tokenPlusMoodleId.moodleId}/`,
        method: 'PATCH',
        headers: tokenPlusMoodleId.getAccess,
        body: tokenPlusMoodleId.body,
      }),
      invalidatesTags: ['Edit'],
    }),
    ...projectsEndpoints(build),
  }),
});
export const {
  useGetStudentsQuery,
  useGetMoodleStudentsQuery,
  useGetPredictionGradesQuery,
  useGetCoursesListQuery,
  useGetStudyPlansQuery,
  useGetMoodleInstructorQuery,
  useGetCourseInstructorQuery,
  useGetCourseTeamQuery,
  useGetFeedbacksListQuery,
  useGetFeedbackQuestionQuery,
  useGetFeedbackAnswersQuery,
  useGetFeedbackAllAnswersQuery,
  useGetFeedbacksByFeedbackIDQuery,
  useGetFeedbacksByResponsesRelatedToStuffQuery,
  useGetFeedbacksByResponsesRelatedToStuffCommonQuery,
  useGetStudentProjectsQuery,
  useGetProjectsSkillFilterDataQuery,
  useGetProjectsStudyLevelFilterDataQuery,
  useGetProjectsSubjectAreaFilterDataQuery,
  useGetProjectsSupervisorFilterDataQuery,
  useGetNotesQuery,
  useDeleteNotesMutation,
  useAddNotesMutation,
  useEdditNotesMutation,
  useArchivedNotesMutation,
  useCreateProjectMutation,
  useGetProjectQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useArchiveProjectMutation,
  useGetEditableInstructorQuery,
  useCreateInstructorInfoMutation,
  useChangeInstructorInfoMutation,
  
  useAddStudentNotesMutation,
  useEdditStudentNotesMutation,
  useGetStudentNotesQuery,
  useDeleteStudentNotesMutation,
  useArchivedStudentNotesMutation
} = Api;
