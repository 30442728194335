import React, { lazy } from 'react';
import { Switch } from 'react-router';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';

const Generators = lazy(() => import('../../../Pages/Generator/Generators'));
const Student = lazy(() => import('../../../Pages/Student/Student'));
const Page404 = lazy(() => import('../../stubs/commonStubs/Page404/Page404'));
const Instructor = lazy(() => import('../../../Pages/Instructor/Instructor'));
const Collaboration = lazy(() => import('../../../Pages/Collaboration/Collaboration'));
const CourseFeedback = lazy(() => import('../../../Pages/CourseFeedback/CourseFeedback'));
const TAEvaluation = lazy(() => import('../../../Pages/TAEvaluation/TAEvaluation'));
const InstructorPositions = lazy(() => import('../../../Pages/InstructorPositions/InstructorPositions'));
const NewSwrchPage = lazy(() => import('../../../Pages/StudentProfileSearch/NewSwrchPage/NewSwrchPage'));

function Route({ logInEnter, setNavBurger, navBurger }) {
  
  return (
    <Switch>
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate/rpd'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate/syllabus'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate/download_syllabus'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        navBurger={navBurger}
        exact
        path='/profile_search'
        component={NewSwrchPage}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        navBurger={navBurger}
        exact
        path='/projects'
        component={Collaboration}
      />

      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id'
        component={Student}
      />

      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/information'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/grades'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/predicted'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/interests'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/github'
        component={Student}
      />
         <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/student/:id/notes'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/information'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/background'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/courses'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/instructor/:id/projects'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/instructor/:id/projects/:projectId'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/notes'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/course_feedback/:id'
        component={CourseFeedback}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/ta_evaluation/:id'
        component={TAEvaluation}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        exact
        path='/'
         component={NewSwrchPage}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor_positions_table'
        component={InstructorPositions}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        path='*'
        component={Page404}
      />
    </Switch>
  );
}

export default Route;
