import React, { useState } from 'react';
import styles from './FooterLanding.module.scss';
import LegalInformation from '../../common/LegalInformation/LegalInformation';

function FooterLanding({request}) {
    const [showPopup, setShowPopup] = useState(false);
    return (
      <div className={styles.footerColor}>
        <footer className={styles.footer}>
          <div className={styles.sectionA}>
            <h1 className={styles.title}>DIGITAL PROFILE</h1>
            <p
              className={styles.text3}
              onClick={() => setShowPopup(true)}
            >
              Legal information
            </p>
            <p className={styles.Copyright}>
              Copyright © 2023 Innopolis&nbsp;University All Rights Reserved
            </p>
          </div>
          <nav className={styles.sectionB}>
          </nav>
          <div className={styles.sectionC}>
            <a
              href={`https://t.me/IU_DP_support`}
              className={styles.contactValueA}
              target='_blank'
              rel='noreferrer'
            >
              <div className={styles.logo}></div>
            </a>
  
            <p className={styles.text}>
              Any questions or suggestions? Contact us by telegram
            </p>
            <p className={styles.textEmail}>BMP@innopolis.ru</p>
            <div className={styles.logos}></div>
          </div>
          <div className={styles.sectionD}>
            <a
              onClick={()=>location.href=`${request}`}
              className={styles.signIn}
            >
              <p className={styles.signInText}>sign in</p>
            </a>
            <p
              className={styles.text2}
              onClick={() => setShowPopup(true)}
            >
              Legal information
            </p>
          </div>
        </footer>
        <LegalInformation
          setShowPopup={setShowPopup}
          showPopup={showPopup}
        />
      </div>
    );
  }
  

export default FooterLanding