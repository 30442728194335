import React, { useState, useRef, useEffect } from 'react';
import styles from './Header.module.css';
import { NavLink } from 'react-router-dom';
import logoDigital from '../../images/лого хедер 1920.svg';
import plusBlack from '../../images/plusBlack.svg';
import logoBurger from '../../images/hamburger.svg';
import { setCookie } from 'react-use-cookie';
import { motion } from 'framer-motion';
import HeaderNavStudent from './HeaderNavStudent';
import HeaderNavGenerator from './HeaderNavGenerator';
import HeaderNavInstructor from './HeaderNavInstructor';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderSearch from './HeaderSearch/HeaderSearch';
function Header({ setLogInEnter, setGithub, navBurger, setNavBurger }) {
  const ref = useRef();
  let history = useHistory();
  let location = useLocation()
  const isError = useSelector((state) => state.token.isError);
  const [miniBarOpen, setMiniBarOpen] = useState(false);
  const linkLOGOUT = `${process.env.REACT_APP_LOGOUT}`;
  const permissions = useSelector((state) => state.token.role);
  const moodleId = useSelector((state) => state.token.moodleId);
  const [linkProfessorPage, setLinkProfessorPage] = useState('');
  const logOut = () => {
    setCookie('access_token', '');
    setCookie('refresh_token', '');
    setLogInEnter(false);
  };
  useEffect(() => {
    if (isError) {
      setCookie('access_token', '');
      setCookie('refresh_token', '');
      setLogInEnter(false);
      history.push('/login_page');
    }
  }, [isError]);
  useEffect(() => {
    if (navBurger && (location.pathname === '/profile_search' || location.pathname === '/' || location.pathname === '/projects')) {
      setNavBurger('');
    }
  }, [location]);
  useEffect(() => {
    if (permissions.includes(process.env.REACT_APP_PERMISSION_PR) && moodleId) {
      setLinkProfessorPage(moodleId);
    }
  }, [permissions, moodleId]);

  return (
    <div className={styles.background}>
      <div className={miniBarOpen ? styles.miniBarBurger : styles.miniBarHidden}>
        <img
          src={plusBlack}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
          className={styles.plusBlack}
        />
        <nav className={styles.navBurger}>
        {permissions && (!permissions.includes(process.env.REACT_APP_PERMISSION_STU) || permissions.length !== 1)  &&  <NavLink
            to='/profile_search'
            className={styles.linkBurger}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            search

          </NavLink>}
          {permissions && (!permissions.includes(process.env.REACT_APP_PERMISSION_STU) || permissions.length !== 1)  && !permissions.includes(process.env.REACT_APP_PERMISSION_SV)  && (
            <NavLink
              to='/generate/rpd'
              className={styles.linkBurger}
              onClick={() => setMiniBarOpen(!miniBarOpen)}
            >
              eduwiki tools
            </NavLink>
          )}
          {permissions && permissions !== process.env.REACT_APP_PERMISSION_SV && <NavLink
            to='/projects'
            className={styles.linkBurger}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            projects
          </NavLink>}
          {permissions &&  permissions.includes(process.env.REACT_APP_PERMISSION_PR) && (
            <NavLink
              to={`/instructor/${linkProfessorPage}/information`}
              className={styles.linkBurger}
              onClick={() => setMiniBarOpen(!miniBarOpen)}
            >
              profile
            </NavLink>
          )}

        </nav>
        {navBurger == 'student' && (
          <HeaderNavStudent
            setGithub={setGithub}
            setMiniBarOpen={setMiniBarOpen}
            miniBarOpen={miniBarOpen}
          />
        )}
        {navBurger == 'generator' && (
          <HeaderNavGenerator
            setMiniBarOpen={setMiniBarOpen}
            miniBarOpen={miniBarOpen}
          />
        )}
        {navBurger == 'instructor' && (
          <HeaderNavInstructor
            setMiniBarOpen={setMiniBarOpen}
            miniBarOpen={miniBarOpen}
          />
        )}


          <a
            href={linkLOGOUT}
            className={styles.logOutBurger}
            target='_blank'
            onClick={logOut}
            rel='noreferrer'
          >
          <div className={styles.logOutBurgerDiv}
        onClick={() => setMiniBarOpen(!miniBarOpen)}>
            <p className={styles.logOutBurger}>log out</p>
            </div>
          </a>
       
          {permissions && permissions.includes(process.env.REACT_APP_PERMISSION_PR) && (
            <NavLink
              to={`/instructor/${linkProfessorPage}/information`}
              className={styles.logOutBurger}
              onClick={() => setMiniBarOpen(!miniBarOpen)}
            >
        
             <div className={styles.logOutBurgerDiv} >
             <p className={styles.logOutBurger}>profile</p>
              </div>
            </NavLink>
              
          )}
      
      </div>
      <div
        className={miniBarOpen ? styles.overlayMiniBarBurger : styles.overlayMiniBarHidden}
        onClick={() => setMiniBarOpen(!miniBarOpen)}
      ></div>
      <motion.header
        className={styles.header}
        initial='hidden'
        whileInView='visible'
        viewport={{ amount: 0.2 }}
      >
        <h1 className={styles.title}>
          <NavLink
            to='/profile_search'
            className={styles.linkTitle}
          >
            <img
              src={logoDigital}
              alt='DIGITAL PROFILE'
              className={styles.logoDigital}
            />
          </NavLink>
        </h1>
        <div className={styles.search}>
       {navBurger && <HeaderSearch/>}
        </div>
        <nav className={styles.nav}>
        {(!permissions.includes(process.env.REACT_APP_PERMISSION_STU) || permissions.length !== 1)  && permissions.length ?  <NavLink
            to='/profile_search'
            className={styles.link}
          >
            search
          </NavLink>
          :<></>}
          {permissions.length && (!permissions.includes(process.env.REACT_APP_PERMISSION_STU) || permissions.length !== 1) && !permissions.includes(process.env.REACT_APP_PERMISSION_SV) ? (

            <NavLink
              to='/generate/rpd'
              className={styles.link}
            >
              eduwiki&nbsp;tools
            </NavLink>
          )
        :<></>}
          {permissions && !permissions.includes(process.env.REACT_APP_PERMISSION_SV) &&<NavLink
            to='/projects'
            className={styles.link}
          >
            projects
          </NavLink>}
        </nav>

        <div className={styles.miniBarDIv}>
          <div
            onClick={() => setMiniBarOpen(!miniBarOpen)}
            className={styles.logo}
          ></div>
          <img
            ref={ref}
            src={logoBurger}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
            className={styles.logoBurger}
          />
          <div className={miniBarOpen ? styles.miniBar : styles.miniBarHidden}>
            <a
              href={linkLOGOUT}
              className={styles.login}
              onClick={logOut}
              target='_blank'
              rel='noreferrer'
            >
              log out
            </a>
           {permissions && permissions.includes(process.env.REACT_APP_PERMISSION_PR) && miniBarOpen && (
            <NavLink
              to={`/instructor/${linkProfessorPage}/information`}
              className={styles.login} 
              onClick={() => setMiniBarOpen(false)}
            >
              profile
            </NavLink>
          )}
          </div>

          <div
            className={miniBarOpen ? styles.miniBarOverlay : styles.miniBarHiddenOverlay}
            onClick={() => setMiniBarOpen(false)}
          ></div>
        </div>
      </motion.header>
    </div>
  );
}

export default Header;
