import React, { useEffect, useState } from 'react';
import styles from './LandingPage.module.scss'
import arrow from '../../images/landing/arrow.svg'
import logoInno from '../../images/LogoUI.png'
import checkMark from '../../images/landing/checkMark.svg'
import screenOne from '../../images/landing/screenOne.svg'
import InstructorImg from '../../images/landing/InstructorImg.svg'
import StudentImg from '../../images/landing/StudentImg.svg'
import EduwikiImg from '../../images/landing/EduwikiImg.svg'
import Instructor from '../../components/LandingComponents/Instructor/Instructor'
import Student from '../../components/LandingComponents/Student/Student'
import Eduwiki from '../../components/LandingComponents/Eduwiki/Eduwiki'
import Slider from '../../components/LandingComponents/Slider/Slider'
import { TitleComponent } from '../../components/helpers/TitleComponent/TitleComponent'
import CookiesPopup from '../../components/common/CookiesPopup/CookiesPopup'
import { motion } from 'framer-motion';
import HeaderLanding from '../../components/LandingComponents/HeaderLanding/HeaderLanding';
import FooterLanding from '../../components/LandingComponents/FooterLanding/FooterLanding';

function LandingPage({ url }) {
  const [newUrl, setNewUrl] = useState('');
  TitleComponent('DP -login page');
  useEffect(() => {
    if (url == '/') {
      setNewUrl('');
    } else {
      setNewUrl(url);
    }
  }, [url]);
  const request = `https://${process.env.REACT_APP_SSO_AUTH_URL}=${process.env.REACT_APP_CLIENT_ID}&resource=${process.env.REACT_APP_RESOURCE}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}${newUrl}&state=${process.env.REACT_APP_STATE}&scope=${process.env.REACT_APP_SCOPE}`;

  function scrollIntoCurrent (current) {
    document.querySelector(`${current}`).scrollIntoView({behavior: "smooth"});
}
  const textAnimation = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const textAnimationRight = {
    hidden: {
      y: 40,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  const linksAnimation = {
    hidden: {
      y: 0,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,

      transition: {
        delay: custom * 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  return (
    <section className={styles.mainSection}>
      <HeaderLanding request={request} />
      <motion.div className={styles.firstInformation}
        initial='hidden'
        whileInView='visible'
        viewport={{ amount: 0.1 }}
      >
        <motion.h1 className={styles.title} variants={textAnimation} custom={1}>
          <span className={styles.titleColor}>Digital Profile</span> for Instructors and Students. All useful information
          in&nbsp;one place
        </motion.h1>

        <div className={styles.buttonsSection}>

          <motion.a className={styles.buttonSignIn}
            onClick={() => location.href = `${request}`}
            variants={textAnimation} custom={1}>
            <p className={styles.buttonText}>sign in</p>
            <div className={styles.arrowArea}>
              <img src={arrow} className={styles.arrow} />
            </div>
          </motion.a>

          <motion.div className={styles.divWithLogo} variants={textAnimation} custom={2}>
            <img src={logoInno} className={styles.logoInno} />
          </motion.div>
        </div>

        <div className={styles.checkMarkSection}>
          <motion.div className={styles.checkMarkItem} variants={textAnimation} custom={1}>
            <img src={checkMark} className={styles.checkMark} />
            <p className={styles.checkMarkText}>look up students grades and contact info</p>
          </motion.div>
          <motion.div className={styles.checkMarkItem} variants={textAnimation} custom={2}>
            <img src={checkMark} className={styles.checkMark} />
            <p className={styles.checkMarkText}>feedback summary for all courses</p>
          </motion.div>
          <motion.div className={styles.checkMarkItem} variants={textAnimation} custom={3}>
            <img src={checkMark} className={styles.checkMark} />
            <p className={styles.checkMarkText}>eduwiki toolkit</p>
          </motion.div>
        </div>
      </motion.div>
      <div className={styles.right}>
        <motion.div className={styles.rightPicture}
          initial='hidden'
          whileInView='visible'
          viewport={{ amount: 0.1 }}>
          <motion.img src={screenOne} className={styles.screenOne} variants={textAnimationRight} custom={1} />
        </motion.div>
      </div>
      <motion.div className={styles.navDiv}
        initial='hidden'
        whileInView='visible'
        viewport={{ amount: 0.1 }}>
        <h4 className={styles.areasTitle}>
          <span className={styles.titleColor}>Digital Profile</span> includes 3 areas:</h4>
        <nav className={styles.navSection}>
          <a onClick={() => scrollIntoCurrent('#instructor')} className={styles.linkSection}>
            <motion.div className={styles.navBlock} variants={linksAnimation} custom={1}>
              <img src={InstructorImg} className={styles.imgBlock} />
              <h6 className={styles.navTitle}>Instructor profile</h6>
              <p className={styles.navText}>Information about professor and personal feedback on all courses</p>
            </motion.div>
          </a>
          <a  onClick={() => scrollIntoCurrent('#student')} className={styles.linkSection}>
            <motion.div className={styles.navBlock} variants={linksAnimation} custom={2}>
              <img src={StudentImg} className={styles.imgBlock} />
              <h6 className={styles.navTitle}>Student profile</h6>
              <p className={styles.navText}>Information about students, which is pulled up from moodle and 1C</p>
            </motion.div>
          </a>
          <a onClick={() => scrollIntoCurrent('#eduwiki')} className={styles.linkSection}>
            <motion.div className={styles.navBlock} variants={linksAnimation} custom={3}>
              <img src={EduwikiImg} className={styles.imgBlock} />
              <h6 className={styles.navTitle}>Eduwiki Tools</h6>
              <p className={styles.navText}>Download and upload syllabuses
                in a simple constructor</p>
            </motion.div>
          </a>
        </nav>
      </motion.div>
      <div id='instructor'>
        <Instructor request={request} />
      </div>
      <div id='student'>
        <Student request={request} />
      </div>
      <div id='eduwiki'>
        <Eduwiki />
      </div>
      <Slider />
      <CookiesPopup />
      <FooterLanding request={request} />
    </section>
  )
}

export default LandingPage