import { useSearchTranslate } from './useSearchTranslate';

export function useFilter(filter, data) {
    const filterItems = (filter) => {
        if (filter.length > 2 && data.length) {
          const translate = useSearchTranslate(filter);
          let filterReg = filter.replace(/^([^\s]+)(\s+)([^\s]+)/, '$3$2$1');
          let newfilter = data.filter(
            (i) =>
              (i.email_value.includes('.university') &&
               ( i.email_value.split('').slice(0, -21).join('').includes(filter) ||
               i.email_value === filter ||
               i.moodle_id == filter ||
               i.email_value.split('').slice(0, -21).join('').includes(filter.toLowerCase()))) ||
              (i.email_value.includes('innopolis.ru') &&
              (i.email_value.split('').slice(0, -13).join('').includes(filter)||
              i.email_value === filter ||
                i.email_value.split('').slice(0, -13).join('').includes(filter.toLowerCase()))) ||
              i.full_name.toLowerCase().includes(filter) ||
              i.full_name.includes(filter) ||
              i.full_name_latin.toLowerCase().includes(filter) ||
              i.full_name_latin.includes(filter) ||
             ( i.full_name.includes(filter.split(' ')[0]) && i.full_name.includes(filter.split(' ')[1]) )||
             ( i.full_name_latin.includes(filter.split(' ')[0]) && i.full_name_latin.includes(filter.split(' ')[1]) )||
              i.telegram_username.includes(filter) ||
              i.full_name.toLowerCase().includes(filterReg) ||
              i.full_name_latin.includes(filterReg) ||
              i.moodle_id == filter ||
              i.full_name_latin.toLowerCase().includes(filterReg) ||
              i.full_name.toLowerCase().includes(translate) ||
              i.full_name_latin.toLowerCase().includes(translate),
          );
          return newfilter;
        }
        return filter;
      };
      const items = filterItems(filter);
  return items;
}
